import React from "react";
import Default from "./Default";
export default class Resume extends React.Component {
  render() {
    return (
      <div className="contianer">
        <Default></Default>
        Inside Resume
      </div>
    );
  }
}
