import React from "react";
export default class Default extends React.Component {
  render() {
    return (
      <div className="navigation-bar navbar-fixed-top">
        <nav className="navbar navbar-inverse navbar-fixed-top">
          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#myNavbar"
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a className="navbar-brand" href="/">
                Kunal Verma
              </a>
            </div>
            <div
              className="collapse navbar-collapse navbar-right"
              id="myNavbar"
            >
              <ul className="nav navbar-nav">
                <li>
                  <a href="/projects">Projects</a>
                </li>
                <li>
                  <a href="/resume">Resume</a>
                </li>
                <li>
                  <a href="/connect">Connect</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
