import React from "react";
export default class ChatServer extends React.Component {
  render() {
    return (
      <div className="EmotionalPolarity">
        <div className="sideBar"></div>
        <div className="projectContent">
          <div className="innerContent">
            <h3>Client-Server Chat Application</h3>
            <p>
              A Simple Client-Server Chat App built using nodejs and mongodb.
            </p>
            <iframe
              src="https://www.youtube.com/embed/hd3ztZtG2L4"
              title="Chat Server"
            ></iframe>
          </div>
        </div>
        <div className="sideBar"></div>
      </div>
    );
  }
}
