import React from "react";
export default class EmotionalPolarity extends React.Component {
  render() {
    return (
      <div className="EmotionalPolarity">
        <div className="sideBar"></div>
        <div className="projectContent">
          <div className="innerContent">
            <h3>Emotional Polarity Classifier</h3>
            <p>
              Python Flask Application for Sentiment Prediction on Airline
              Reviews.
            </p>
            <iframe
              src="https://www.youtube.com/embed/yUpBQO5nvuc"
              title="Emotional Polarity Classifier"
            ></iframe>
          </div>
        </div>
        <div className="sideBar"></div>
      </div>
    );
  }
}
