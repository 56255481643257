import React from "react";
import myImge from "./main-colored.jpg";
import Facebook from "./Facebook";
import Github from "./Github";
import Linkedin from "./LinkedIn";
import Instagram from "./Instagram";
export default class Introduction extends React.Component {
  render() {
    return (
      <div className="Introduction-container">
        <div className="NameHeader">
          <div className="NameHeader-Information">
            <h1>Kunal Verma</h1>
            <hr className="upper"></hr>
            <br />
            <h2>Software | Web Developer</h2>
          </div>
        </div>
        <div className="Content">
          <div className="Image">
            <img src={myImge} className=" img-responsive" alt="myImage" />
          </div>
          <div className="aboutMe">
            <h1>
              <b>ABOUT ME</b>
            </h1>
            <br />
            <p className="paragraph">
              Hi, This is <strong>Kunal</strong>. I graduated from the
              University of British Columbia with a Masters Degree in
              Engineering, majoring Electrical and Computer Engineering. Being a
              programmer, I love to solve problems. I am very passionate about
              modern Software Development and also possess a knack of interest
              in Machine Learning and Natural Language Processing.
            </p>
          </div>
          <div className="connections">
            <div className="header">
              <h1>
                <b>Let's Connect</b>
              </h1>
            </div>
            <div className="icons">
              <Github></Github>
              <Linkedin></Linkedin>
              <Facebook></Facebook>
              <Instagram></Instagram>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
