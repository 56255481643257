import React from "react";
import Default from "./Default";
import EmotionalPolarity from "./EmotionalPolarity";
import ChatServer from "./ChatServer";
import TicTacToe from "./TicTacToe";
import CodeHunt from "./CodeHunt";
export default class Projects extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="ProjectHeading">
          <h1>Project Work</h1>
        </div>
        <Default></Default>
        <EmotionalPolarity></EmotionalPolarity>
        <ChatServer></ChatServer>
        <CodeHunt></CodeHunt>
        <TicTacToe></TicTacToe>
      </div>
    );
  }
}
