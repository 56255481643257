import React from "react";
export default class CodeHunt extends React.Component {
  render() {
    return (
      <div className="EmotionalPolarity">
        <div className="sideBar"></div>
        <div className="projectContent">
          <div className="innerContent">
            <h3>Code Hunt</h3>
            <p>Desktop Based Quiz Application using Java Swing.</p>
            <iframe
              src="https://www.youtube.com/embed/6XITBJsihLA"
              title="Code Hunt"
            ></iframe>
          </div>
        </div>
        <div className="sideBar"></div>
      </div>
    );
  }
}
