import React from "react";
export default class Linkedin extends React.Component {
  render() {
    return (
      <div className="linkedin">
        <a
          className="social"
          href="https://www.linkedin.com/in/vkunal1996"
          target="_blank"
          rel="noreferrer"
        >
          <i
            aria-hidden="true"
            className="fa fa-linkedin"
            data-toggle="tooltip"
            title="Linkedin"
          ></i>
        </a>
      </div>
    );
  }
}
