// import { EmailJSResponseStatus } from "emailjs-com";
import React from "react";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";

export default class Form extends React.Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      subject: "",
      message: "",
      email: "",
      displayMessage: "",
    };
  }
  handleFirstNameChange = (event) => {
    this.setState({ firstname: event.target.value });
  };
  handleLastNameChange = (e) => {
    this.setState({ lastname: e.target.value });
  };
  handleSubjectChange = (e) => {
    this.setState({ subject: e.target.value });
  };
  handleMessageChange = (e) => {
    this.setState({ message: e.target.value });
  };
  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };
  submitForm = (event) => {
    console.log(event);
    console.log("Outside EmailJs");
    init("user_LaOFJeytJtx5pPcw4ZL10");
    const send = emailjs
      .send("service_z7v66kr", "template_fc2lkiv", {
        subject: this.state.subject,
        to_name: "Kunal Verma",
        from_name: this.state.firstname + " " + this.state.lastname,
        message: this.state.message,
        email: this.state.email,
        reply_to: "vkunal1996portfolio@gmail.com",
      })
      .then(
        function (response) {
          console.log(response)
          console.log("Success!", response.status, response.text);
          return "SUCCESS";
        },
        function (err) {
          return "FAILED";
        }
      );
    console.log(send);
    event.preventDefault();
    var that = this;
    send.then((result) => {
      if (result === "SUCCESS") {
        this.setState({
          displayMessage:
            "Thank you! " +
            that.state.firstname +
            " " +
            that.state.lastname +
            " for dropping a message. We will connect with you soon.",
          firstname: "",
          lastname: "",
          email: "",
          message: "",
          subject: "",
        });
      } else if (result === "FAILED") {
        this.setState({
          displayMessage:
            "uh -oh ! It seems like there is some error from our end. Please Try Again Later",
        });
      }
    });
  };

  render() {
    return (
      <div className="Form">
        <form onSubmit={this.submitForm}>
          <div className="formHeading">
            <h1>Drop a Message</h1>
          </div>
          <div className="Name">
            <div className="firstName">
              <input
                type="text"
                placeholder="First Name"
                value={this.state.firstname}
                onChange={this.handleFirstNameChange}
                required
                className="form-control"
              />
            </div>
            <div className="lastName">
              <input
                type="text"
                placeholder="Last Name"
                value={this.state.lastname}
                onChange={this.handleLastNameChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="Email">
            <input
              type="email"
              placeholder="Email"
              required
              value={this.state.email}
              onChange={this.handleEmailChange}
              className="form-control"
            ></input>
          </div>
          <div className="Subject">
            <input
              type="text"
              placeholder="Subject"
              required
              value={this.state.subject}
              onChange={this.handleSubjectChange}
              className="form-control"
            ></input>
          </div>
          <div className="Message">
            <textarea
              placeholder="Your Message"
              required
              value={this.state.message}
              onChange={this.handleMessageChange}
              className="form-control"
              rows="15"
              cols="60"
            ></textarea>
          </div>
          <div className="SendButton">
            <input
              type="submit"
              className="btn btn-success form-control"
              value="Send Message"
            />
          </div>
        </form>
        <div className="DisplayMessage">
          <p>{this.state.displayMessage}</p>
        </div>
      </div>
    );
  }
}
