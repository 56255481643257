import React from "react";
export default class TicTacToe extends React.Component {
  render() {
    return (
      <div className="EmotionalPolarity">
        <div className="sideBar"></div>
        <div className="projectContent">
          <div className="innerContent">
            <h3>Tic Tac Toe</h3>
            <p>A GUI game Application built using Java Swing </p>
            <iframe
              src="https://www.youtube.com/embed/0b-_lJYCJpA"
              title="Tic Tac Toe"
            ></iframe>
          </div>
        </div>
        <div className="sideBar"></div>
      </div>
    );
  }
}
