import "./App.css";
import Landing from "./components/Landing";
import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Projects from "./components/Projects";
import Connect from "./components/Connect";
import Resume from "./components/Resume";
import HttpsRedirect from "react-https-redirect";

class App extends React.Component {
  render() {
    return (
      <HttpsRedirect>
        <Router>
          <Route exact path="/" component={Landing} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/resume" component={Resume} />
          <Route exact path="/connect" component={Connect} />
        </Router>
      </HttpsRedirect>
    );
  }
}

export default App;
