import React from "react";
import Default from "./Default";
import Introduction from "./Introduction";
export default class Landing extends React.Component {
  render() {
    return (
      <div className="container">
        <Default></Default>
        <Introduction></Introduction>
      </div>
    );
  }
}
