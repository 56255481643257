import React from "react";
export default class Facebook extends React.Component {
  render() {
    return (
      <div className="facebook">
        <a
          className="social"
          href="https://www.facebook.com/vkunal1996"
          target="_blank"
          rel="noreferrer"
        >
          <i
            aria-hidden="true"
            className="fa fa-facebook"
            data-toggle="tooltip"
            title="Facebook"
          ></i>
        </a>
      </div>
    );
  }
}
