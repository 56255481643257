import React from "react";
export default class Instagram extends React.Component {
  render() {
    return (
      <div className="instagram">
        <a
          className="social"
          href="https://www.instagram.com/genuinelygeek"
          target="_blank"
          rel="noreferrer"
        >
          <i
            aria-hidden="true"
            className="fa fa-instagram"
            data-toggle="tooltip"
            title="Instagram"
          ></i>
        </a>
      </div>
    );
  }
}
