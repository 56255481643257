import React from "react";
import Default from "./Default";
import Form from "./Form";
export default class Connect extends React.Component {
  render() {
    return (
      <div className="contianer">
        <Default></Default>
        <Form></Form>
      </div>
    );
  }
}
